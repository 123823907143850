import type { Pagination, PaginationQuery } from '@/types'
import { rest } from '@/utils'

export const getMarketingEvents = async (
  params: PaginationQuery<{ event_id?: string }>,
): Promise<Pagination<haiper.MarketingEvent>> => {
  const res = await rest.get('/v1/events', { params })
  return res.data
}

export const submitWorkToEvent = async (event_id: string, work_id: string) => {
  const res = await rest.post(`/v1/event/${event_id}/submit/${work_id}`)
  return res.data
}

export const getEventSubmissions = async ({
  event_id,
  ...params
}: PaginationQuery<{ event_id?: string; user_id?: string }>): Promise<Pagination<haiper.MarketingEventSubmission>> => {
  const res = await rest.get(`/v1/event/${event_id}/submissions`, { params })
  return res.data
}

export const getUserProfileSubmissions = async ({
  user_id,
  ...params
}: PaginationQuery<{ user_id: string }>): Promise<Pagination<haiper.MarketingEventSubmission>> => {
  const res = await rest.get(`/v2/user/${user_id}/submissions`, { params })
  return res.data
}
